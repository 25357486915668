body,
div,
th,
td {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif !important;
    font-style: normal;
    font-weight: 100 !important;
    /* color: #000000; */
}
input,
a,
p,
hr,
pre,
ol,
ul,
li,
i,
b,
span,
br,
img,
svg,
table,
tbody,
thead,
tfoot,
tr,
label,
button,
select,
option,
textarea {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100 !important;
    font-style: normal;
}
input::-webkit-input-placeholder {
    color: #aaa !important;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaa !important;
    opacity: 1;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa !important;
}
input::placeholder {
    /* Most modern browsers support this now. */
    color: #aaa !important;
}
.ReactModal__Overlay {
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #14152585 !important;
}
.ReactModal__Content {
    background: rgba(27, 26, 41, 0.56) !important;
    border: 0.5px rgba(255, 255, 255, 0.56) solid;
    position: static;
    padding: 25px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
}
.ReactModal__Content > div {
    flex: 1;
    min-height: 0; /*fix auto height flex scroll issue*/
    display: flex;
    flex-direction: column;
}
@media (max-width: 450px) {
    .ReactModal__Overlay {
        padding: 0 10px;
    }
    .ReactModal__Content {
        width: 100% !important;
        padding: 15px;
    }
}

.calculator-modal-edition {
    z-index: 999999;
}

.react-tabs__tab-panel:not(.react-tabs__tab-panel--selected) {
    display: none;
}

input {
    padding: 1px 2px;
    line-height: 1em;
}
